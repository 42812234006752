import { assignSelectedItem } from './_dropdown-handlers'

export const navigateToPage = () => {
    const navigateToButtons = document.querySelectorAll('.tab-item-radio[data-page]')
    for (const navigateToButton of navigateToButtons) {
        navigateToButton.addEventListener('click', () => {
            location.pathname = navigateToButton.dataset.page
        })
    }
}

export const navToTeamPage = () => {
    // Format active team dropdown
    const arrayURLPath = window.location.pathname.split('/')
    const arrayTeamName = arrayURLPath[arrayURLPath.length - 1]
    const selectedTeamName = document.querySelector('.body-block__team-dropdown .menu-team.active .title')
    const unselectedTeamsContainer = document.querySelectorAll('.body-block__team-dropdown .menu-teams .menu-team')
    const newSelectedTeamName = document.querySelector(`.menu-teams .menu-team .title[data-team='${arrayTeamName}']`)

    // SelectedTeamName.innerHTML = newSelectedTeamName.innerHTML
    assignSelectedItem(selectedTeamName, newSelectedTeamName)

    for (const unselectedTeamContainer of unselectedTeamsContainer) {
        if (unselectedTeamContainer.textContent === 'Team') {
            unselectedTeamContainer.querySelector('.title').innerHTML = newSelectedTeamName.innerHTML
        }
    }
}
