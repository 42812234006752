/* eslint-disable no-prototype-builtins */
const defaultNavigationOptions = {
    editionCookie: "edition",
    language: "en",
    otherLocation: "https://www.nba.com/",
    usLocation: "https://www.nba.com/",
    optionText: "NBA.com",
};
const globalNavigationClassName = "#sibNbaTicker";
const editionPickerClassName = "#edition_wrapper";
const editionPickerMessageClassName = "#edition_message";
const editionPickerFormClassName = "#setDeafultEditionForm";
const editionPickerFormContainerClassName = "#edition";
const editionForm = document.querySelector(
    `${globalNavigationClassName} ${editionPickerFormClassName}`,
);

const deleteCookie = (name, path) => {
    setCookie(name, "", -1, path);
};

const setCookie = (name, value, expires, path, domain, secure) => {
    const cookieParams = [];
    if (name == "path" || name == "expires" || name == "domain") {
        name = "badCookieName";
    }

    cookieParams.push(`${name}=${escape(value)}`);
    cookieParams.push(`expires=${expires}`);

    if (path) {
        cookieParams.push(`path=${path}`);
    }

    if (domain) {
        cookieParams.push(`domain=${domain}`);
    }

    if (secure) {
        cookieParams.push(`secure`);
    }

    document.cookie = cookieParams.join("; ");
};

const getCookie = (name) => {
    const cookie = document.cookie.match("(^|;) ?" + name + "=([^;]*)(;|$)");
    return cookie ? cookie[2] : null;
};

const NBA = {
    show: (selector) => {
        const msgElement = document.querySelector(selector);
        if (!msgElement.classList.contains("message-open")) {
            msgElement.classList.add("message-open");

            document.documentElement.style.overflow = "hidden";
            document.documentElement.style.height = "100%";
        }
        if (msgElement.classList.contains("message-closed")) {
            msgElement.classList.remove("message-closed");
        }
    },
    close: (selector) => {
        const msgElement = document.querySelector(selector);
        if (!msgElement.classList.contains("message-closed")) {
            msgElement.classList.add("message-closed");

            document.documentElement.style.overflow = "auto";
            document.documentElement.style.height = "auto";
        }
        if (msgElement.classList.contains("message-open")) {
            msgElement.classList.remove("message-open");
        }
    },
    handleClosingModal: () => {
        const editioPickerContainer = document.querySelector(
            editionPickerFormContainerClassName,
        );
        const closingButton = editionForm.querySelector(
            "#setDeafultEditionCloseButton",
        );
        const editionPickerFormContainer = document.querySelector(
            editionPickerFormContainerClassName,
        );

        closingButton.addEventListener("click", () => {
            NBA.close(editionPickerFormContainerClassName);
        });

        editionPickerFormContainer.addEventListener("click", (e) => {
            let target = e.target;
            if (target !== editionForm) {
                NBA.close(editionPickerFormContainerClassName);
            }
        });

        document.addEventListener("keydown", (event) => {
            const isEditionPickerOpen =
                editioPickerContainer.classList.contains("message-open");
            if (event.key === "Escape" && isEditionPickerOpen) {
                NBA.close(editionPickerFormContainerClassName);
            }
        });
    },
    handleEdition: () => {
        const pickerElement = document.querySelector(
            `${editionPickerClassName}`,
        );
        if (!pickerElement) {
            return;
        }
        NBA.show(editionPickerClassName);
        NBA.show(editionPickerFormContainerClassName);
        NBA.handleClosingModal();
    },
    getEditionCookie: () => {
        const editionCookie =
            window.navigationOptions.editionCookie ||
            defaultNavigationOptions.editionCookie;

        return getCookie(editionCookie);
    },
    setEditionCookie: (value, expires) => {
        const editionCookie =
            window.navigationOptions.editionCookie ||
            defaultNavigationOptions.editionCookie;

        if (getCookie(editionCookie)) {
            deleteCookie(editionCookie, "/");
        }
        setCookie(editionCookie, value, expires, "/");
    },
    setHpDefault: (locale) => {
        const sevenDays = 1000 * 60 * 60 * 24 * 7;
        const today = new Date();
        today.setTime(today.getTime());
        const expires = new Date(today.getTime() + sevenDays);
        const timeout = 4000;

        NBA.setEditionCookie(locale, expires);
        NBA.show(editionPickerMessageClassName);
        NBA.close(editionPickerFormContainerClassName);
        setTimeout(() => {
            NBA.close(editionPickerClassName);
            NBA.close(editionPickerMessageClassName);
            NBA.close(editionPickerFormContainerClassName);
        }, timeout);
    },
    setEditionInput: () => {
        const inputs = editionForm.querySelectorAll(
            'input[type="radio"][name="edition"]',
        );
        const language =
            NBA.getEditionCookie() || window.navigationOptions.language;
        Object.keys(inputs).forEach((elem) => {
            if (inputs[elem].value == language) {
                inputs[elem].checked = true;
            }
        });
    },
    createEditionInput: () => {
        const inputs = editionForm.querySelectorAll(
            'input[type="radio"][name="edition"]',
        );
        const parent = inputs[0].parentNode;
        const target = document.createElement("input");
        const targetLang =
            window.navigationOptions.language ||
            window.navigationOptions.language;
        const text =
            window.navigationOptions.siteName ||
            `${window.navigationOptions.language} NBA`;
        const textNode = document.createTextNode(
            " " + text.toUpperCase() + " ",
        );

        target.setAttribute("type", "radio");
        target.setAttribute("name", "edition");
        target.classList.add("mr-3.5", "ml-1.5", "cursor-pointer");
        target.value = targetLang;
        parent.insertBefore(target, inputs[0].previousSibling);
        parent.insertBefore(textNode, target);

        target.addEventListener("click", (e) => {
            NBA.setHpDefault(e.target.value);
        });
    },
};

export const nbaSetDefaultEdition = () => {
    const globalNavigationElement = document.querySelector(
        globalNavigationClassName,
    );
    const settings = JSON.parse(
        globalNavigationElement.getAttribute("data-settings") || "{}",
    );

    const nbaFunctions = {
        set_HP_default: NBA.setHpDefault,
        handle_edition: NBA.handleEdition,
    };
    const navigationOptions =
        settings.navigationOptions || defaultNavigationOptions;
    const nbaFunctionsExists = () => {
        return Object.keys(nbaFunctions).some((elem) => {
            return window.hasOwnProperty(elem);
        });
    };

    if (!window.hasOwnProperty("navigationOptions")) {
        window.navigationOptions = navigationOptions;
    }

    const setDefaultEdition = (nbaFunctions, nbaFunctionsExists) => {
        if (!nbaFunctionsExists()) {
            Object.keys(nbaFunctions).forEach(function (param) {
                if (!window.hasOwnProperty(param)) {
                    window[param] = nbaFunctions[param];
                }
            });

            NBA.createEditionInput();
        }
        NBA.setEditionInput();
    };

    const editionLink = document.querySelector("#setDefaultEditionButton");
    editionLink.addEventListener("click", NBA.handleEdition);
    const usEditionRadio = document.querySelectorAll(".setDefaultHPInput");
    if (usEditionRadio) {
        usEditionRadio.forEach((radio) => {
            radio.addEventListener("change", () => {
                NBA.setHpDefault("us");
            });
        });
    }

    if (
        document.readyState === "complete" ||
        document.readyState === "interactive"
    ) {
        setDefaultEdition(nbaFunctions, nbaFunctionsExists);
    } else {
        document.addEventListener("DOMContentLoaded", function () {
            setDefaultEdition(nbaFunctions, nbaFunctionsExists);
        });
    }

    const controlsDiv = document.querySelector(".controls");
    if (controlsDiv) {
        const textNodes = Array.from(controlsDiv.childNodes).filter(
            (node) => node.nodeType === Node.TEXT_NODE,
        );

        if (textNodes.length > 0) {
            textNodes[0].textContent = "";
        }

        let childInput = controlsDiv.childNodes[0];

        const tickerElement = document.getElementById("sibNbaTicker");
        const settingsData = tickerElement.getAttribute("data-settings");
        const settingsObj = JSON.parse(settingsData);
        const optionText = settingsObj.navigationOptions.optionText;

        let newNode = document.createTextNode(optionText);
        childInput.parentNode.insertBefore(newNode, childInput);
    }
};

export const nbaRedirection = () => {
    const linkElement = document.querySelector("#nbaSiteLink");

    // The NBA site link is sometimes not present on the page
    // This can happen when is_nba_sib is set to true and page.nba_ticker_menu is empty
    if (!linkElement) {
        return;
    }

    const dataSettings = document
        .querySelector("#sibNbaTicker")
        .getAttribute("data-settings");
    const { navigationOptions } = JSON.parse(dataSettings);

    linkElement.addEventListener("click", function (event) {
        event.preventDefault();

        const editionCookie = NBA.getEditionCookie();
        const usLocation = navigationOptions.usLocation;

        if (!editionCookie) {
            window.location.href = usLocation;
        } else if (editionCookie !== "us") {
            window.location.href = navigationOptions.otherLocation;
        } else {
            window.location.href = navigationOptions.usLocation;
        }
    });
};
