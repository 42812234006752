export const accordionFaqsHandler = () => {
    const faqsContainers = document.querySelectorAll('.faqs-block__content-container')

    // Add active class on first iteration
    faqsContainers[0].querySelectorAll('.faqs-accordion')[0].classList.add('active')

    // Set accordion on every container
    for (const faqsContainer of faqsContainers) {
        const accordions = faqsContainer.querySelectorAll('.faqs-accordion')

        // Apply initial style on active panel
        for (const accordion of accordions) {
            if (accordion.classList.contains('active')) {
                accordion.nextElementSibling.style.maxHeight = `${accordion.nextElementSibling.scrollHeight}px`
            }

            // Apply accordion on click
            accordion.addEventListener('click', () => {
                const panel = accordion.nextElementSibling
                const coursePanels = faqsContainer.querySelectorAll('.faqs-panel')
                const courseAccordionsActive = faqsContainer.querySelectorAll('.faqs-accordion.active')

                if (panel.style.maxHeight) {
                    panel.style.maxHeight = null
                    accordion.classList.remove('active')
                } else {
                    for (const courseAccordionActive of courseAccordionsActive) {
                        courseAccordionActive.classList.remove('active')
                    }

                    for (const coursePanel of coursePanels) {
                        accordion.classList.remove('active')
                        coursePanel.style.maxHeight = null
                    }

                    panel.style.maxHeight = `${panel.scrollHeight}px`
                    accordion.classList.add('active')
                }
            })
        }
    }
}
