export const boxingWidget = () => {
    const boxingPage = document.querySelector("body.widget-boxing")

    if (boxingPage) {
        window.generateBoxingURL = function (entity) {
            let url;
            const origin = window.location.href.replace(/\/(boxer-list|fight-calendar|fight-page|boxer-profile).*/,''); // return full boxing url, https://www.sportingnews.com/[edition]/boxing
            switch (entity.type) {
                case 'boxing:fighter':
                url = new URL(origin + '/boxer-profile/2-contestant-' + entity.references.boxrec.get('fighterId'));
                break;

                case 'boxing:match':
                url = new URL(origin + '/fight-page/2-fixture-' + entity.references.boxrec.get('boutId'));
                break;

                default:
                url = new URL(origin);
            }
            return url
        };
    }
};
