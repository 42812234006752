/* eslint-disable no-undef */
export const optaHideSelections = () => {
  const optaWidgetDropdownBtns = document.querySelectorAll('.opta-section-dropdown__header');
  const optaWidgetDropdownLists = document.querySelectorAll('.opta-section-dropdown__list');
  
  optaWidgetDropdownBtns.forEach((dropdownBtn) => dropdownBtn.classList.remove('is-active'));
  optaWidgetDropdownLists.forEach((dropdownList) => dropdownList.classList.remove('is-active'));
}

export const optaCricketScorecardRender = (competition, matches) => {
  const wrapper = document.querySelector('#scorecard-widget-wrapper');
  const div = document.createElement('div');
  div.classList.add('widget-opta-widget', 'sn-widget-alias--scorecard');
  div.innerHTML = `
    <div class="scorecard-title"><span>Scorecard</span></div>
    <opta-widget
      widget="score_card"
      competition="${competition}"
      season="0"
      match="${matches}"
      template="normal"
      live="false"
      show_match_header="true"
      show_crests="true"
      show_competition_name="true"
      show_match_description="true"
      show_date="true"
      date_format="dddd D MMMM"
      show_venue="true"
      show_officials="on_field"
      show_toss="true"
      show_innings_breakdown="true"
      show_current_batting="true"
      show_best_batting="1"
      show_best_bowling="1"
      show_state_of_play="true"
      navigation="tabs"
      default_nav="1"
      show_batting="true"
      show_mins_batted="true"
      show_fours="true"
      show_sixes="true"
      show_strike_rate="true"
      show_bowling="true"
      show_economy="true"
      show_dot_balls="true"
      show_bowling_extras="true"
      show_fow="true"
      show_partnerships="true"
      show_unfinished_partnerships="true"
      team_naming="full"
      player_naming="initial"
      show_logo="false"
      show_title="false"
      breakpoints="400, 700"
      sport="cricket">
    </opta-widget>`
  wrapper.append(div);
  Opta.start();
}

export const optaCricketStandingsRender = competition => {
	const wrapper = document.querySelector('#standings-widget-wrapper');
	const div = document.createElement('div');
	div.classList.add('widget-opta-widget', 'sn-widget-alias--standings');
	div.innerHTML = `
      <opta-widget
        widget="standings"
        template="normal"
        competition="${competition}"
        season="0"
        live="false"
        navigation="concertina"
        default_nav="1"
        show_key="true"
        show_crests="true"
        points_in_first_column="true"
        competition_naming="full"
        team_naming="full"
        sorting="false"
        show_live="false"
        show_logo="false"
        show_title="true"
        breakpoints="400, 700"
        sport="cricket">
      </opta-widget>`;
	wrapper.append(div);
	Opta.start();
};

export const optaCricketFixturesRender = competition => {
	const wrapper = document.querySelector('#fixtures-widget-wrapper');
	const div = document.createElement('div');
	div.classList.add('widget-opta-widget', 'sn-widget-alias--fixtures');
	div.innerHTML = `
      <opta-widget
        widget="fixtures"
        template="normal"
        competition="${competition}"
        season="0"
        live="false"
        show_venue="true"
        match_status="all"
        grouping="date"
        show_grouping="true"
        navigation="none"
        default_nav="1"
        start_on_current="true"
        sub_grouping="date"
        show_subgrouping="false"
        order_by="date_ascending"
        show_crests="true"
        show_competition_name="true"
        date_format="dddd D MMMM YYYY"
        competition_naming="full"
        team_naming="full"
        match_link="scorecard"
        pre_match="true"
        show_live="false"
        show_logo="false"
        show_title="true"
        breakpoints="400"
        sport="cricket">
      </opta-widget>`
    wrapper.append(div);
    Opta.start();
}

export const optaGolfLeaderboardRender = (competition, season, match) => {
    const wrapper = document.querySelector('#leaderboard-widget-wrapper');
    const div = document.createElement('div');
    div.classList.add('widget-opta-widget', 'sn-widget-alias--leaderboard');
    div.innerHTML = `
      <opta-widget
        widget="leaderboard"
        template="normal"
        competition="${competition}"
        season="${season}"
        match="${match}"
        live="false"
        show_date="true"
        date_format="D MMM"
        show_dates_combined="false"
        show_competition_name="true"
        show_season_name="false"
        show_course_name="true"
        show_position="true"
        show_rounds="true"
        show_nationality="true"
        player_highlighting="false"
        player_naming="initial"
        show_live="false"
        show_logo="true"
        show_title="true"
        breakpoints="320"
        sport="golf">
      </opta-widget>`
    wrapper.append(div);
    Opta.start();
}

export const optaGolfScheduleRender = (competition, season, match) => {
    const wrapper = document.querySelector('#schedule-widget-wrapper');
    const div = document.createElement('div');
    div.classList.add('widget-opta-widget', 'sn-widget-alias--schedule');
    div.innerHTML = `
      <opta-widget
        widget="schedule"
        template="normal"
        competition="${competition}"
        season="${season}"
        match="${match}"
        date_from="2001-02-01"
        date_to="2025-12-12"
        order_by="date_ascending"
        show_date="true"
        date_format="D MMM"
        show_dates_combined="false"
        show_course_name="true"
        show_winner="true"
        show_logo="true"
        show_title="true"
        breakpoints="450"
        sport="golf">
      </opta-widget>`
    wrapper.append(div);
    Opta.start();
}

export const optaTennisScoreboardRender = (season, competition, tournament) => {
    const wrapper = document.querySelector('#scoreboard-widget-wrapper');
    const div = document.createElement('div');
    div.classList.add('widget-opta-widget', 'sn-widget-alias--scoreboard');
    div.innerHTML = `
      <opta-widget
        widget="live_scores"
        template="normal"
        live="false"
        competition="${competition}"
        season="${season}"
        tournament="${tournament}"
        match_status="all"
        grouping="round"
        show_grouping="true"
        limit="0"
        navigation="tabs"
        default_nav="1"
        start_on_current="true"
        sub_grouping="date"
        show_subgrouping="true"
        order_by="date_ascending"
        show_nationality="true"
        date_format="dddd D MMMM YYYY"
        competition_naming="full"
        player_naming="full"
        show_live="false"
        show_logo="true"
        show_title="true"
        breakpoints="450"
        sport="tennis">
      </opta-widget>`
    wrapper.append(div);
    Opta.start();
}

export const optaTennisScheduleRender = (season, competition) => {
	const wrapper = document.querySelector('#schedule-widget-wrapper');
	const div = document.createElement('div');
	div.classList.add('widget-opta-widget', 'sn-widget-alias--schedule');
	div.innerHTML = `<opta-widget
    widget="schedule"
    template="normal"
    competition="${ competition }"
    season="${ season }"
    grouping="date"
    show_grouping="true"
    sub_grouping="date"
    show_subgrouping="false"
    type="all"
    navigation="none"
    default_nav="1"
    start_on_current="false"
    order_by="date_ascending"
    show_date="true"
    date_format="D MMM"
    month_date_format="MMMM"
    show_surface="true"
    show_country="true"
    show_internationals="false"
    pre_match="false"
    show_logo="false"
    show_title="true"
    breakpoints="450"
    sport="tennis">
  </opta-widget>`
  wrapper.append(div);
  Opta.start();
}
