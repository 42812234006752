/* eslint-disable no-undef */
export const leagueDropdownToggleHandler = () => {
    const activeLeague = document.querySelector('.league.active')
    const leagueList = document.querySelector('.league-list')
    if (activeLeague) {
        activeLeague.addEventListener(
            'click',
            () => {
                leagueList.classList.toggle('show')
                activeLeague.classList.toggle('opened')
            },
            false
        )
        leagueList.addEventListener(
            'click',
            () => {
                leagueList.classList.remove('show')
                activeLeague.classList.remove('opened')
            },
            false
        )
    }
}

export const leagueNewSelectedHandler = () => {
    const leagueList = document.querySelector('.league-list')
    const leagueListItems = document.querySelectorAll('.league-list > .league')
    let selectedLeagueName = document.querySelector('.league.active .league-name')

    for (const leagueListItem of leagueListItems) {
        const leagueName = leagueListItem.querySelector('.league-name')
        if (selectedLeagueName.textContent === leagueName.textContent) {
            leagueListItem.remove()
        }

        leagueListItem.addEventListener('click', (event) => {
            const newSelectedLeagueName = event.target.querySelector('.league-name')

            const siblingSelectedItem =
                selectedLeagueName.nextSibling === newSelectedLeagueName
                    ? selectedLeagueName
                    : selectedLeagueName.nextSibling

            newSelectedLeagueName.parentNode.insertBefore(selectedLeagueName, newSelectedLeagueName)
            siblingSelectedItem.before(newSelectedLeagueName)

            for (const node of [...leagueList.children].sort((a, b) => (a.textContent > b.textContent ? 1 : -1)))
                leagueList.append(node)

            leagueList.classList.remove('show')

            selectedLeagueName = document.querySelector('.league.active .league-name')

            const selectedLeagueCode = selectedLeagueName.getAttribute('metabet-code')
            refreshWidget(selectedLeagueCode)
        })
    }
}

const refreshWidget = (leagueCode) => {
    const scoresDOM = document.querySelector('.metabet-betsinabox-scores')
    const scheduleDOM = document.querySelector('.metabet-betsinabox-schedule')
    const standingsDOM = document.querySelector('.metabet-betsinabox-standings')
    const teamsDOM = document.querySelector('.metabet-betsinabox-teams')
    const startDate = document.querySelector('.metabet-datepicker')

    if (scoresDOM && startDate) {
        mb_insertBetsInABoxScores(scoresDOM, startDate.value ? new Date(startDate.value) : null, 'soccer', leagueCode)
    }

    if (scheduleDOM && startDate) {
        mb_insertBetsInABoxSchedule(
            scheduleDOM,
            startDate.value ? new Date(startDate.value) : null,
            'soccer',
            leagueCode
        )
    }

    if (teamsDOM) {
        mb_insertBetsInABoxTeams(teamsDOM, 'soccer', leagueCode)
    }

    if (standingsDOM) {
        mb_insertBetsInABoxStandings(standingsDOM, 'soccer', leagueCode)
    }
}
