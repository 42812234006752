import { dropdownToggleHandler, decrementUnselectedItems, assignSelectedItem } from '../helpers/_dropdown-handlers'

export const previousYearHandler = () => {
    const selectedYear = document.querySelector('.menu-year.active')
    const unselectedYearsContainer = document.querySelector('.menu-years')
    const unselectedYears = document.querySelectorAll('.menu-years > .menu-year')

    // Toggle dropdown items
    dropdownToggleHandler(selectedYear, unselectedYearsContainer)

    for (const unselectedYear of unselectedYears) {
        unselectedYear.addEventListener('click', (event) => {
            const selectedYearTitle = selectedYear.querySelector('.title')
            const newSelectedYearTitle = event.target.querySelector('.title')
            const contentIndex = Number.parseInt(newSelectedYearTitle.dataset.order, 10)
            const yearContents = Array.from(
                document.querySelector('.previous-block .content-container').querySelectorAll('table')
            )

            // Assign new selected year
            unselectedYearsContainer.classList.remove('show')
            assignSelectedItem(selectedYearTitle, newSelectedYearTitle)

            // Sort unselected years
            decrementUnselectedItems(unselectedYearsContainer)

            // Show year table
            for (const yearContent of yearContents) yearContent.style.display = 'none'
            yearContents[contentIndex].style.display = 'table'
        })
    }
}
