export const fetchMoreNews = () => {
    const moreNewsBottomSection = document.querySelector('.more-from-tsn-section')

    const moreNewsSidebarSection = document.querySelector('.more-from-tsn-sidebar')

    async function getData(moreNewsUrl) {
        try {
            const response = await fetch(moreNewsUrl)
            if (!response.ok) {
                throw new Error(`Error! status: ${response.status}`)
            }

            const data = await response.text()

            return data
        } catch (error) {
            console.log(error)
        }
    }

    if (moreNewsBottomSection) {
        const moreNewsBottomrRoute = moreNewsBottomSection.dataset.route
        getData(moreNewsBottomrRoute).then((html) => {
            moreNewsBottomSection.innerHTML = html
        })
    }

    if (moreNewsSidebarSection) {
        const moreNewsSidebarRoute = moreNewsSidebarSection.dataset.route
        getData(moreNewsSidebarRoute).then((html) => {
            moreNewsSidebarSection.innerHTML = html
        })
    }
}
