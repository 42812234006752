import { waitForElmBySelectorIndex } from '../helpers/_observer-handler'

export const metabetRedirectionHandler = () => {
    const bodyClassValue = document.body.classList.value
    const classArray = bodyClassValue.split(' ')

    let extractedSport, extractedEdition

    classArray.forEach(className => {
        if (className.startsWith('path-')) {
            extractedSport = className.split('-')[1]
        }
        if (className.includes('-edition')) {
            extractedEdition = className.split('-')[0]
        }
    });

    if (!window.sn_entityRedirect) {
        window.sn_entityRedirect = function (entity) {
            if (entity["entity"] === "game") {
                entity["entity"] = "game"
                window.location.href = "/" + extractedEdition + "/" + extractedSport + "/" + entity["entity"] + "/" + entity["id"]
            }

            if (entity["entity"] === "team") {
                window.location.href = "/" + extractedEdition + "/" + extractedSport + "/" + entity["entity"] + "/" + entity["id"]
            }
        }
    }
}

export const scoresTeamLinkHandler = () => {
    const isScoresWidgetPage = document.querySelector("body.widget-scores")

    if (isScoresWidgetPage) {
        waitForElmBySelectorIndex('.metabet-betsinabox-time', 0).then(()=>{
            const scoresBoards = document.querySelectorAll(".metabet-betsinabox-scores ul > li")
            for (const scoresBoard of scoresBoards) {
                const gameCenter = scoresBoard.querySelector(".metabet-betsinabox-time")
                const teamBlocks = scoresBoard.querySelectorAll(".metabet-betsinabox-team1Logo, .metabet-betsinabox-team1Name, .metabet-betsinabox-team2Logo, .metabet-betsinabox-team2Name")

                for (const block of teamBlocks) {
                    block.addEventListener("click", (event)=> {
                        event.preventDefault()
                        gameCenter.click()
                    })
                }
            }
        })
    }
}

export const scheduleTeamLinkHandler = () => {
    const isScheduleWidgetPage = document.querySelector("body.widget-schedule")

    if (isScheduleWidgetPage) {
        waitForElmBySelectorIndex("[metabet-observe-resizes] table", 0).then(()=>{
            const tableRow = document.querySelectorAll("[metabet-observe-resizes] tbody tr")
            for (const row of tableRow) {
                const teams = row.querySelectorAll("td.metabet-betsinabox-team > a")
                const score = row.querySelector("td.metabet-betsinabox-score > a")

                for (const team of teams) {
                    team.addEventListener("click", (event)=> {
                        event.preventDefault()
                        score.click()
                    })
                }
            }
        })
    }
}

