// Viewport breakpoints
export const isMobile = () => {
    return window.innerWidth <= 768;
};

export const isDesktop = () => {
    return !(window.innerWidth <= 768);
};

// News page items count
// Note: Changing the count wil require to set the number of child of 'top-news__container' to hide.
export const newsCount = {
    initialItemsCount: 21,
    addItemsCount: 21,
};

export const isTemplateEnv = () => {
    const hostname = window.location.hostname;
    return (
        hostname === "c1.non-prod.ng-sportingnews.com" ||
        hostname === "local.ng-sportingnews.com" ||
        hostname === "127.0.0.1"
    );
};

export const isInViewport = (str) => {
    const elem = document.querySelector(str);
    var rect = elem.getBoundingClientRect();

    return (
        rect.bottom > 0 &&
        rect.right > 0 &&
        rect.left < (window.Width || document.documentElement.clientWidth) &&
        rect.top < (window.Height || document.documentElement.clientHeight)
    );
};

export const showElem = (e) => {
    e.classList.remove("inactive");
    e.classList.add("active");
    setTimeout(() => {
        e.classList.add("show");
    }, 100);
};

export const hideElem = (e) => {
    e.classList.remove("show");

    setTimeout(() => {
        e.classList.remove("active");
        e.classList.add("inactive");
    }, 500);
};

// Device Specific
export const isIosDevices = () => {
    return /iPad|iPhone|iPod/.test(navigator.userAgent);
};

export const isSafari = () => {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
};
