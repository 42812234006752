import { dropdownToggleHandler } from '../helpers/_dropdown-handlers'

/**
 * Enables the click/show/hide of the Round filter on round and team pages
 */
export const roundNavigationHandler = () => {
    const selectedRound = document.querySelector('.body-block__round-dropdown .menu-round.active')
    const unselectedRoundsContainer = document.querySelector('.body-block__round-dropdown .menu-rounds')

    dropdownToggleHandler(selectedRound, unselectedRoundsContainer)
}

export const overflowFilterHandler = () => {
    const draftPickContainer = document.querySelectorAll('.body-block__list .pick-container')
    const teamFilterContainer = document.querySelector('.filter-container ul.menu .menu-teams')
    const roundFilterContainer = document.querySelector('.filter-container ul.menu .menu-rounds')

    const addOverflowToFilter = () => {
        teamFilterContainer.classList.add('overflow-auto')
        roundFilterContainer.classList.add('overflow-auto')
    }

    if (draftPickContainer.length < 2) addOverflowToFilter()
}
