// Sort items in descending order
export const decrementUnselectedItems = (menuItems) => {
    for (const node of [...menuItems.children].sort((a, b) => (a.textContent < b.textContent ? 1 : -1)))
        menuItems.append(node)
}

// Sort items in ascending order
export const incrementUnselectedItems = (menuItems) => {
    for (const node of [...menuItems.children].sort((a, b) => (a.textContent > b.textContent ? 1 : -1)))
        menuItems.append(node)
}

// Switch selected item position
export const assignSelectedItem = (selectedTitle, newSelectedTitle) => {
    const siblingSelectedItem =
        selectedTitle.nextSibling === newSelectedTitle ? selectedTitle : selectedTitle.nextSibling

    newSelectedTitle.parentNode.insertBefore(selectedTitle, newSelectedTitle)
    siblingSelectedItem.before(newSelectedTitle)
}

// Toggle dropdown items
export const dropdownToggleHandler = (activeItem, unactiveItems) => {
    if (activeItem) {
        document.addEventListener(
            'click',
            (event) => {
                const boolIsInside = activeItem.contains(event.target)
                if (boolIsInside) {
                    unactiveItems.classList.toggle('show')
                    activeItem.classList.toggle('opened')
                } else {
                    unactiveItems.classList.remove('show')
                    activeItem.classList.remove('opened')
                }
            },
            false
        )
    }
}
