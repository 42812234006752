export const relatedArticlesHandler = (selector) => {
    const relatedArticles = document.querySelector('.related-articles-container')
    const targetElement = document.querySelectorAll(selector)
    const middlePart = Math.floor(targetElement.length / 2)
    const lastPart = targetElement.length - 1

    // Place related articles at the middle part of article content
    if (relatedArticles && middlePart && targetElement.length > 3) {
        targetElement[middlePart].before(relatedArticles)
        return
    }

    // Place related articles before the last part of article content
    if (relatedArticles && lastPart && targetElement.length > 0) {
        targetElement[lastPart].before(relatedArticles)
    }
}

export const newRelatedArticlesHandler = (selector) => {
    const relatedArticles = document.querySelector('#article-related-article-block')
    const targetElement = document.querySelectorAll(selector)
    const middlePart = Math.floor(targetElement.length / 2)
    const lastPart = targetElement.length - 1

    // Place related articles at the middle part of article content
    if (relatedArticles && middlePart && targetElement.length > 3) {
        targetElement[middlePart].before(relatedArticles)
        return
    }

    // Place related articles before the last part of article content
    if (relatedArticles && lastPart && targetElement.length > 0) {
        targetElement[lastPart].before(relatedArticles)
    }
}
