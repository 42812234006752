import { waitForElmBySelectorIndex } from "../helpers/_observer-handler";
import { isInViewport } from "../../common/_constants";

export const outbrainThumbnailsLazyloader = () => {
    waitForElmBySelectorIndex(".ob-smartfeed-wrapper", 0).then(() => {
        const outbrainOuterContainer = document.querySelector(
            ".OUTBRAIN.tsn-container",
        );
        const outbrainSkeleton = document.querySelector("#outbrain-skeleton");
        const outbrainContainer = document.querySelector(".ob-widget");

        const showOutbrain = () => {
            const isOutbrainLoaded =
                outbrainOuterContainer.getAttribute("data-ob-mark");

            if (isInViewport("#outbrain-skeleton")) {
                if (isOutbrainLoaded) {
                    setTimeout(() => {
                        if (!outbrainSkeleton.classList?.contains("hidden"))
                            outbrainSkeleton.classList.add("hidden");
                        if (outbrainContainer.classList?.contains("hidden"))
                            outbrainContainer.classList.remove("hidden");
                    }, 1000);
                }
            }
        };

        if (!outbrainContainer.classList?.contains("hidden"))
            outbrainContainer.classList.add("hidden");

        window.onload = () => {
            showOutbrain();
        };

        window.addEventListener("scroll", function () {
            showOutbrain();
        });
    });
};
