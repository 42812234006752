export const getNextElementUntil = (element, selector) => {
    // Setup siblings array and get next sibling
    const siblings = []
    let next = element.nextElementSibling

    // Loop through all siblings
    while (next) {
        // If the matching item is found, quit
        if (selector && next.matches(selector)) break

        // Otherwise, push to array
        siblings.push(next)

        // Get the next sibling
        next = next.nextElementSibling
    }

    return siblings
}

export const countElements = (elements, displayLength) => {
    const selectElements = document.querySelectorAll(elements)
    const displayElement = document.querySelector(displayLength)

    // Check if selectElements are already loaded
    if (selectElements && displayElement) {
        // Display element length
        displayElement.innerHTML = selectElements.length
    }
}

export const getSiblings = (element) => {
    return Array.from(element.parentNode.children).filter(child => child !== element);
}
