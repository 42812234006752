import allLocales from "../../locale/locale-list.json";

export class TSNi18n {
    constructor() {
        let timezoneToLocaleDict = {};
        const compatibleLocales =
            Intl.DateTimeFormat.supportedLocalesOf(allLocales);

        for (const locale of compatibleLocales) {
            try {
                const intlLocale = new Intl.Locale(locale);
                for (const timeZone of intlLocale.timeZones) {
                    if (!(timeZone in timezoneToLocaleDict)) {
                        timezoneToLocaleDict[timeZone] = [];
                    }
                    timezoneToLocaleDict[timeZone].push(locale);
                }
            } catch {
                // console.log(`Skipping invalid locale ${locale}`);
            }
        }
        this.timezoneToLocaleDict = timezoneToLocaleDict;
    }

    getLocaleFromEdition(edition) {
        switch (edition) {
            case "argentina":
                return "es-ar";
            case "australia":
                return "en-au";
            case "brazil":
                return "pt-br";
            case "canada":
                return "en-ca";
            case "germany":
                return "de-de";
            case "hong_kong":
                return "en-hk";
            case "india":
                return "en-in";
            case "japan":
                return "ja-jp";
            case "malaysia":
                return "en-my";
            case "mexico":
                return "es-mx";
            case "new_zealand":
                return "en-nz";
            case "singapore":
                return "en-sg";
            case "south_korea":
                return "ko-kr";
            case "spain":
                return "es-es";
            case "thailand":
                return "th-th";
            case "united_kingdom":
                return "en-gb";
            case "usa":
                return "en-us";
            case "usa_spain":
                return "es-us";
            case "vietnam":
                return "vi-vn";
            default:
                return "en-us";
        }
    }

    getTimeZoneAbbreviation(dateObj, timeZone) {
        let timeZoneLocale;
        try {
            timeZoneLocale = this.timezoneToLocaleDict[timeZone][0];
        } catch {
            // Default to en-us when no locale found
            timeZoneLocale = "en-us";
        }
        const timeZoneFormatter = new Intl.DateTimeFormat(timeZoneLocale, {
            timeZone: timeZone,
            timeZoneName: "short",
        });
        const parts = timeZoneFormatter.formatToParts(dateObj);
        return parts.find((x) => x.type === "timeZoneName").value;
    }
}
