import { isDesktop, hideElem } from "../../common/_constants";

export const mouseNavigation = () => {

    if(isDesktop){
        /* Mouse click outside the visible "Skip to main content" button will hide the button. */
        document.addEventListener('click', () => {
            const skiptocontentElem = document.querySelector('#skiptocontent');

            if(skiptocontentElem.classList.contains('active')) {
                hideElem(skiptocontentElem);          
            }
        });
    }
}