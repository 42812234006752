/* eslint-disable no-undef */
import { optaHideSelections, optaCricketScorecardRender, optaCricketStandingsRender, optaCricketFixturesRender, optaGolfLeaderboardRender, optaGolfScheduleRender, optaTennisScoreboardRender, optaTennisScheduleRender } from './_render';

export const hideDropdownListHandle = () => {
    window.addEventListener('click', (event) => {
        const getSelector = (event) => {
            const currentElem = event.target;
            let currentSelector = currentElem.parentNode.localName;
            if (currentElem.parentNode.className) {
                currentSelector += "." + currentElem.parentNode.className.split(' ').join('.');
            };

            currentSelector += " " + currentElem.localName;
            if (currentElem.className) {
                currentSelector += "." + currentElem.className.split(' ').join('.');
            };

            return currentSelector;
        };

        const dropdownHeader = document.querySelector('span.opta-section-dropdown__header.is-active');
        const dropdownList = document.querySelector('ul.opta-section-dropdown__list.is-hidden.is-active');

        /* Remove OPTA dropdown is-active class if user clicked anywhere aside the selection header or list */
        if (!getSelector(event).match('li.item a.link|span.opta-section-dropdown__header') && dropdownHeader && dropdownList) {
            optaHideSelections();
        };
    });
};

export const scorecardCricketSelectClickHandle = () => {
    const lists = document.querySelectorAll(".opta-section-select.multiple.cricket.select-scorecard .opta-section-dropdown__list a.link");
    lists.forEach(item => {
        item.addEventListener("click", (event) => {
            event.preventDefault();

            const data = item.dataset;
            const selected = event.currentTarget.parentNode.parentNode.parentNode.children[1];

            const snWidgetAlias = document.querySelector(".sn-widget-alias--scorecard");
            snWidgetAlias.parentNode.removeChild(snWidgetAlias);

            if (data.competitionname && data.competitionid) {
                selected.children[0].textContent = data.competitionname;
                selected.dataset.currentcompetitionname = data.competitionname;
                selected.dataset.currentcompetitionid = data.competitionid;
            } else if (data.matchname && data.matchid) {
                selected.children[0].textContent = data.matchname;
                selected.dataset.currentmatchname = data.matchname;
                selected.dataset.currentmatchid = data.matchid;
                selected.dataset.currentmatchdate = data.matchdate;
            }

            const competitionId = document.querySelector('nav.opta-section-dropdown.opta-tournament-wrapper span.opta-section-dropdown__header').dataset.currentcompetitionid;
            const matchId = document.querySelector('nav.opta-section-dropdown.opta-matches-wrapper span.opta-section-dropdown__header').dataset.currentmatchid;

            optaCricketScorecardRender(competitionId, matchId);

            optaHideSelections();
        });
    });
}

export const standingCricketSelectClickHandle = () => {
	const list = document.querySelectorAll('.opta-section-select.multiple.cricket.select-standings .opta-seasons-wrapper a.link');

    list.forEach(item => {
        item.addEventListener('click', event => {
            event.preventDefault();
            const currentItem = item.dataset.tournament_name;
            const dropdown = item.closest('.opta-section-dropdown');
            dropdown.querySelector('.opta-section-dropdown__header').innerHTML = currentItem + '<span class=\'dropdown\'></span>';
            dropdown.querySelector('.opta-section-dropdown__header').dataset.current_tournament_id = item.dataset.tournament_id;
            document.querySelector('.sn-widget-alias--standings').remove();
            optaCricketStandingsRender(dropdown.querySelector('.opta-section-dropdown__header').dataset.current_tournament_id);
        });
    });
};

export const fixtureCricketSelectClickHandle = () => {
    const list = document.querySelectorAll(".opta-section-select.multiple.cricket.select-fixture .opta-section-dropdown__list a.link");

    list.forEach(item => {
        item.addEventListener("click", (event) => {
          event.preventDefault();

          const currentItem = item.dataset.tournament_name;

          const header = item.closest(".opta-section-dropdown").querySelector(".opta-section-dropdown__header");
          header.querySelector(".title").textContent = currentItem;
          header.dataset.current_tournament_id = item.dataset.tournament_id;
          header.dataset.current_tournament_name = item.dataset.tournament_name;

          const snWidgetAlias = document.querySelector(".sn-widget-alias--fixtures");
          snWidgetAlias.parentNode.removeChild(snWidgetAlias);

          const competitionId = document.querySelector(".opta-fixture-wrapper .opta-section-dropdown__header").dataset.current_tournament_id;
          optaCricketFixturesRender(competitionId);
        });
    });
}

export const leaderboardGolfSelectClickHandle = () => {
    const lists = document.querySelectorAll(".opta-section-select.multiple.golf.select-leaderboard a.link");
    lists.forEach(item => {
      item.addEventListener("click", (event) => {
        event.preventDefault();

        const snWidgetAlias = document.querySelector(".sn-widget-alias--leaderboard");
        snWidgetAlias.parentNode.removeChild(snWidgetAlias);

        const data = item.dataset;
        const selected = event.currentTarget.parentNode.parentNode.parentNode.children[1];

        if (data.competitionname && data.competitionid) {
            selected.children[0].textContent = data.competitionname;
            selected.dataset.currentcompetitionname = data.competitionname;
            selected.dataset.currentcompetitionid = data.competitionid;
        } else if (data.seasonname && data.seasonid) {
            selected.children[0].textContent = data.seasonname;
            selected.dataset.currentseasonname = data.seasonname;
            selected.dataset.currentseasonid = data.seasonid;
        } else if (data.matchname && data.matchid) {
            selected.children[0].textContent = data.matchname;
            selected.dataset.currentmatchname = data.matchname;
            selected.dataset.currentmatchid = data.matchid;
        }

        const competitionId = document.querySelector('nav.opta-section-dropdown.opta-competition-wrapper span.opta-section-dropdown__header').dataset.currentcompetitionid;
        const seasonId = document.querySelector('nav.opta-section-dropdown.opta-seasons-wrapper span.opta-section-dropdown__header').dataset.currentseasonid;
        const matchId = document.querySelector('nav.opta-section-dropdown.opta-match-wrapper span.opta-section-dropdown__header').dataset.currentmatchid;

        optaGolfLeaderboardRender(competitionId, seasonId, matchId);

        optaHideSelections();
      });
  });
}

export const scheduleGolfSelectClickHandle = () => {
    const lists = document.querySelectorAll(".opta-section-select.multiple.golf.select-schedule a.link");
    lists.forEach(item => {
      item.addEventListener("click", (event) => {
        event.preventDefault();

        const snWidgetAlias = document.querySelector(".sn-widget-alias--schedule");
        snWidgetAlias.parentNode.removeChild(snWidgetAlias);

        const data = item.dataset;
        const selected = event.currentTarget.parentNode.parentNode.parentNode.children[1];

        if (data.competitionname && data.competitionid) {
            selected.children[0].textContent = data.competitionname;
            selected.dataset.currentcompetitionname = data.competitionname;
            selected.dataset.currentcompetitionid = data.competitionid;
        } else if (data.seasonname && data.seasonid) {
            selected.children[0].textContent = data.seasonname;
            selected.dataset.currentseasonname = data.seasonname;
            selected.dataset.currentseasonid = data.seasonid;
        }

        const competitionId = document.querySelector('nav.opta-section-dropdown.opta-competition-wrapper span.opta-section-dropdown__header').dataset.currentcompetitionid;
        const seasonId = document.querySelector('nav.opta-section-dropdown.opta-seasons-wrapper span.opta-section-dropdown__header').dataset.currentseasonid;
        const matchId = "7i3akjuav7em1b4k91pfi72fo";

        optaGolfScheduleRender(competitionId, seasonId, matchId);

        optaHideSelections();
      });
  });
}

export const scoreboardTennisSelectClickHandle = () => {
    const lists = document.querySelectorAll(".opta-section-select.multiple.tennis.select-scoreboard .opta-section-dropdown__list a.link");
    lists.forEach(item => {
        item.addEventListener("click", (event) => {
          event.preventDefault();

          const snWidgetAlias = document.querySelector(".sn-widget-alias--scoreboard");
          snWidgetAlias.parentNode.removeChild(snWidgetAlias);

          const data = item.dataset;
          const selected = event.currentTarget.parentNode.parentNode.parentNode.children[1];

          if (data.seasonname && data.seasonid) {
              selected.children[0].textContent = data.seasonname;
              selected.dataset.currentseasonname = data.seasonname;
              selected.dataset.currentseasonid = data.seasonid;
          } else if (data.tournamentname && data.tournamentid) {
              selected.children[0].textContent = data.tournamentname;
              selected.dataset.currenttournamentname = data.tournamentname;
              selected.dataset.currenttournamentid = data.tournamentid;
          } else if (data.competitionname && data.competitionid) {
              selected.children[0].textContent = data.competitionname;
              selected.dataset.currentcompetitionname = data.competitionname;
              selected.dataset.currentcompetitionid = data.competitionid;
          }

          const seasonId = document.querySelector('nav.opta-section-dropdown.opta-seasons-wrapper span.opta-section-dropdown__header').dataset.currentseasonid;
          const tournamentId = document.querySelector('nav.opta-section-dropdown.opta-tournament-wrapper span.opta-section-dropdown__header').dataset.currenttournamentid;
          const competitionId = document.querySelector('nav.opta-section-dropdown.opta-competition-wrapper span.opta-section-dropdown__header').dataset.currentcompetitionid;

          optaTennisScoreboardRender(seasonId, competitionId, tournamentId);

          optaHideSelections();
      });
  });
}

export const scheduleTennisSelectClickHandle = () => {
    const optaWidgetList = document.querySelectorAll(".opta-section-select.multiple.tennis.select-schedule .opta-section-dropdown__list a.link");

    for (const optaWidget of optaWidgetList) {
        optaWidget.addEventListener("click", (event) => {
            event.preventDefault();

            const data = optaWidget.dataset;
            const selected = event.currentTarget.parentNode.parentNode.parentNode.children[1];
            const competitionId = selected.dataset.currentcompetitionid;
            const seasonId = data.seasonid;
            const seasonName = data.seasonname;
            const snWidgetAlias = document.querySelector(".sn-widget-alias--schedule");

            snWidgetAlias.parentNode.removeChild(snWidgetAlias);
                
            selected.children[0].textContent = seasonName;
            selected.dataset.currentseasonname = seasonName;
            selected.datasetcurrentseasonid = seasonId;

            optaTennisScheduleRender(seasonId, competitionId);

            optaHideSelections();
        });
    }
}

export const optaDropdownsHandler = () => {
    const optaWidgetDropdownBtns = document.querySelectorAll('.opta-section-dropdown__header');

    for (const optaWidgetDropdownBtn of optaWidgetDropdownBtns) {
        optaWidgetDropdownBtn.addEventListener('click', (event) => {
            const dropArrow = event.currentTarget;
            const list = event.currentTarget.parentNode.lastElementChild;
            const isActive = dropArrow.classList.contains('is-active');

            optaHideSelections();

            if (!isActive) {
                dropArrow.classList.add('is-active');
                list.classList.add('is-active');
            }
        });
    }
}
