import { isMobile } from "../../common/_constants";
import { getSiblings } from "../helpers/_element-handlers";

// Check TSN-1804 for more details
export const previousWinnersHandler = () => {
    let slideContent = document.querySelectorAll('.slide_content');
    let index = 0;
    let mobileYearsTab = document.querySelectorAll('.year[for^="mobile-tab"]');
    let desktopYearsTab = document.querySelectorAll('.year[for^="desktop-tab"]');
    let btnPrev = document.querySelector('.btn_prev');
    let btnNext = document.querySelector('.btn_next');

    const showContent = (contentIndex) => {
        slideContent[contentIndex].style.display = 'flex';
        getSiblings(slideContent[contentIndex]).forEach(sibling => sibling.style.display = 'none');

        if (isMobile()) {
            mobileYearsTab[contentIndex].style.display = 'flex';
            getSiblings(mobileYearsTab[contentIndex]).forEach(sibling => sibling.style.display = 'none');
        }
    };

    const handleButtonClick = (increment) => {
        index += increment;
        if (index >= 0 && index < mobileYearsTab.length) {
            showContent(index);
        } else {
            index -= increment;
        }
    };

    // mobile
    if (isMobile() && btnPrev && btnNext) {
        showContent(index);

        btnPrev.addEventListener('click', () => handleButtonClick(-1));
        btnNext.addEventListener('click', () => handleButtonClick(1));
    } else {
        // desktop
        desktopYearsTab.forEach((yearTab, tabOrder) => {
            yearTab.setAttribute('tab-order', tabOrder);
            yearTab.addEventListener('click', () => showContent(tabOrder));
        });
    }
};

export const jumpToLatestNewsHandler = () => {
    const ALLOWANCE_FOR_STICKY_NAVIGATION = isMobile() ? 0 : 100

    let scrollButton = document.querySelector(".march-madness-page .scroll-to");
    let targetElement = document.getElementById("latestNewsAnchor");

    document.body.addEventListener("click", function (event) {
        if (event.target === scrollButton) {
            event.preventDefault();
            event.stopPropagation();

            let targetOffsetTop = targetElement.offsetTop - ALLOWANCE_FOR_STICKY_NAVIGATION;

            window.scrollTo({
                top: targetOffsetTop,
                behavior: 'smooth'
            });
        }
    });
}
