import { dropdownToggleHandler } from './_dropdown-handlers'

export const seasonsFilterHandler = () => {
    const selectedYear = document.querySelector('.menu-year.active')
    const unselectedYearsContainer = document.querySelector('.menu-years')
    const selectedYearsContainers = document.querySelectorAll('.body-block__list')

    // Show initial set year content
    for (const selectedYearsContainer of selectedYearsContainers) {
        selectedYearsContainer.classList.add('hide')
    }

    selectedYearsContainers[0].classList.remove('hide')

    // Toggle dropdown items
    dropdownToggleHandler(selectedYear, unselectedYearsContainer)
}

export const teamsFilterHandler = () => {
    const selectedTeam = document.querySelector('.body-block__team-dropdown .menu-team.active')
    const unselectedTeamsContainer = document.querySelector('.body-block__team-dropdown .menu-teams')
    // Toggle dropdown items
    dropdownToggleHandler(selectedTeam, unselectedTeamsContainer)
}
