/* ----------------------
    Revcontent Advert Settings:
*/
export const revcontentDesktopSidebar = {
    id: 'rc-widget-d5a8b6',
    rcWidget: undefined,
    widgetHost: 'habitat',
    endpoint: '//trends.revcontent.com',
    widgetId: '268089',
}

export const revcontentDesktopBottom = {
    id: 'rc-widget-02b296',
    rcWidget: null,
    widgetHost: 'habitat',
    endpoint: '//trends.revcontent.com',
    widgetId: '268087',
}

export const revcontentMobile = {
    id: 'rc-widget-268163',
    rcWidget: null,
    widgetHost: 'habitat',
    endpoint: '//trends.revcontent.com',
    revMore: null,
    revMorePreviewHeight: 0,
    revMoreButtonText: 'Read More',
    revMoreWrapperId: 'rev-more-wrapper',
    widgetId: '268163',
}

/* ----------------------
    Longitude Advert Settings:
*/
export const mobileTopMPU1Id = 'ad-mobileTopMpu1'
