export const accordionTeamDetailsHandler = () => {
    const pickContainers = document.querySelectorAll('.pick-container')

    // Apply handler per each pick container
    for (const pickContainer of pickContainers) {
        const summaryHeight = pickContainer.querySelector('.pick-container__details .summary-container')
        const summaryContent = pickContainer.querySelector(
            '.pick-container__details .summary-container .summary-content'
        )
        const chevronIcon = pickContainer.querySelector('.chevron')

        // Set paragraph into two lines and display chevron if can be expanded
        if (summaryHeight !== null && summaryContent !== null && summaryContent.offsetHeight > 22) {
            summaryHeight.style.height = '1.38rem'
            chevronIcon.style.display = 'inline-block'

            // Unset height value of summary content and toggle active for chevron
            pickContainer.addEventListener('click', () => {
                pickContainer.classList.toggle('active')
                summaryHeight.style.height =
                    summaryHeight.style.height === '1.38rem'
                        ? `${(summaryContent.offsetHeight / 22) * 1.38}rem`
                        : '1.38rem'
            })
        }
    }
}
