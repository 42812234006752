// Related functions on menu component
import { isMobile, isDesktop, isTemplateEnv } from '../common/_constants'
import { monitorForChanges, waitForElmBySelectorIndex } from '../components/helpers/_observer-handler'

// Enable scrolls of side navigation (mobile)
export const getHeightSideNav = () => {
    const sideNav = document.querySelector('.tsn-primary-menu__top .tsn-primary-menu__container .primary-menu-wrapper')
    const additionalMenuWrapper = document.querySelector('.additional-menu-wrapper')
    const primaryMenuTop = document.querySelector('.tsn-primary-menu__top')
    const additionalMenuHeader = document.querySelector('.additional-header--menu')
    const additionalMenuHeight = additionalMenuHeader ? additionalMenuHeader.offsetHeight : 0
    const withGlobalMenu = document.querySelector('#sibNbaTicker')

    if (!sideNav) return

    if (isMobile()) {
        let sideNavHeight = `${window.innerHeight - primaryMenuTop.offsetHeight - additionalMenuHeight}px`

        sideNav.style.height = sideNavHeight
        additionalMenuWrapper.style.top = sideNavHeight

        if (withGlobalMenu) {
            let globalMenuHeight = withGlobalMenu.offsetHeight
            let sideNavHeight = `${window.innerHeight - primaryMenuTop.offsetHeight - additionalMenuHeight - globalMenuHeight}px`

            sideNav.style.height = sideNavHeight
            additionalMenuWrapper.style.top = sideNavHeight
        }
    } else {
        sideNav.style.height = 'unset'
        additionalMenuWrapper.style.top = 'unset'
    }
}

// Mobile navigation toggle
export const mobileMenuButtonHandler = () => {
    const mobileMenuBtn = document.querySelector('.mobile-menu-btn')
    const additionalMenuWrapper = document.querySelector('.additional-menu-wrapper')
    const bodyContainer = document.querySelector('html')
    const elementsToToggleOverlay = [
        document.querySelector("main"),
        document.querySelector(".nba-sib-header"),
        document.querySelector(".nba-scoreboard-container"),
        document.querySelector(".tsn-primary-menu__bottom"),
        document.querySelector(".tsn-score-ribbon"),
        document.querySelector(".oddsServeCreative"),
        document.querySelector(".error-404"),
        document.querySelector("#ad-topBanner1")
    ];

    const mobileBtnHandler = () => {
        mobileMenuBtn.classList.toggle('active')
        mobileMenuBtn.classList.contains('active')
            ? (bodyContainer.style.overflow = 'hidden')
            : (bodyContainer.style.overflow = 'auto')

        elementsToToggleOverlay.forEach(element => {
            if (element) {
                element.classList.toggle('overlay');
            }
        });

        if (additionalMenuWrapper) {
            additionalMenuWrapper.classList.toggle('active')
        }
    }

    if (mobileMenuBtn) {
        mobileMenuBtn.removeEventListener('click', mobileBtnHandler);
        mobileMenuBtn.addEventListener('click', mobileBtnHandler);
    }
}

export const resetSidebarMenu = () => {
    const mobileMenuBtn = document.querySelector('.mobile-menu-btn')
    const elementsToToggleOverlay = [
        document.querySelector("main"),
        document.querySelector(".nba-sib-header"),
        document.querySelector(".nba-scoreboard-container"),
        document.querySelector(".tsn-primary-menu__bottom"),
        document.querySelector(".tsn-score-ribbon"),
        document.querySelector(".oddsServeCreative"),
        document.querySelector(".error-404"),
        document.querySelector("#ad-topBanner1")
    ];
    const additionalMenuWrapper = document.querySelector('.additional-menu-wrapper')
    const bodyContainer = document.querySelector('html')

    if (!mobileMenuBtn) return

    if (isDesktop() && mobileMenuBtn.classList.contains('active')) {
        mobileMenuBtn.classList.remove('active')
        elementsToToggleOverlay.forEach(element => {
            if (element) {
                element.classList.remove('overlay');
            }
        });
        additionalMenuWrapper.classList.remove('active')
        bodyContainer.style.overflow = 'auto'
    }
}

// Prepare second level menu (mobile)
export const mobileMenuArrowHAndler = () => {
    const hasChildItems = document.querySelectorAll('.primary-menu-wrapper .mobile-sidebar-menu > .menu > .menu-item .menu')
    const thirdLevelMenuItems = document.querySelectorAll('.menu-item.has-children .menu-item > .menu')

    for (const hasChildItem of hasChildItems) {
        const menuItem = hasChildItem.closest('.menu-item');

        if (hasChildItem.childElementCount > 1) {
            menuItem.classList.add('has-children');

            if (!menuItem.querySelector('.has-children__arrow')) {
                menuItem.append(childrenArrowContainer());
            }
        }
    }

    for (const thirdLevelMenuItem of thirdLevelMenuItems) {
        thirdLevelMenuItem.closest('.menu-item').classList.add('has-children__second-level')
        thirdLevelMenuItem.closest('.menu-item').append(childrenSecondArrowContainer())
    }

    const menuArrows = document.querySelectorAll('.primary-menu-wrapper .mobile-sidebar-menu > .menu .has-children > i')

    if (menuArrows) {
        for (const menuArrow of menuArrows) {
            if (!menuArrow._clickHandlerAdded) {
                menuArrow.addEventListener('click', (event) => {
                    menuArrow.closest('.has-children').classList.toggle('is-open-mobile')
                })
                menuArrow._clickHandlerAdded = true;
            }
        }
    }

    if (menuArrows && isDesktop()) {
        for (const menuArrow of menuArrows) {
            menuArrow.closest('.has-children').classList.remove('is-open-mobile')
            menuArrow.remove()
        }
    }
}

// Handles third level menu toogle and classnames
export const thirdLevelMenuHandler = () => {
    const thirdLevelItems = document.querySelectorAll('.has-children-second__arrow')
    const arrayItem = []

    for (const thirdLevelItem of thirdLevelItems) {
        arrayItem.push(thirdLevelItem)

        thirdLevelItem.addEventListener('click', (event) => {
            event.preventDefault()
            const closestSecondLevel = thirdLevelItem.closest('.has-children__second-level')
            closestSecondLevel.classList.toggle('second-level-is-open-mobile')

            if (closestSecondLevel.classList.contains('second-level-is-open-mobile')) {
                const prevElement = thirdLevelItem.previousElementSibling
                const isOpenMobileParent = closestSecondLevel.closest('.is-open-mobile')

                prevElement.style.display = 'block'
                prevElement.classList.add('third-level-item')

                isOpenMobileParent.querySelectorAll('.has-children__arrow ~ .third-level-item').forEach((el) => {
                    el.remove()
                })

                const clonedElement = prevElement.cloneNode(true)
                isOpenMobileParent.appendChild(clonedElement)
                prevElement.style.display = 'none'
            } else {
                const isOpenMobileParent = closestSecondLevel.closest('.is-open-mobile')

                isOpenMobileParent.querySelectorAll('.has-children__arrow ~ .third-level-item').forEach((el) => {
                    el.remove()
                })

                thirdLevelItem.previousElementSibling.style.display = 'block'
            }

            for (const itemFromList of arrayItem) {
                if (itemFromList !== thirdLevelItem && itemFromList.parentElement.classList.contains('second-level-is-open-mobile')) {
                    itemFromList.parentElement.classList.remove('second-level-is-open-mobile')
                }
            }
        })
    }
}

// Handles sticky behaviour of components
export const stickyAdvertsHandler = () => {
    const topMpuContainer = document.querySelector(".top-mpu-container")
    const stickyMpus = [
        document.querySelector("#ad-desktopTopMpu1"),
        document.querySelector("#ad-stickyMPU")
    ]
    const primaryElement = document.querySelector(".tsn-primary-menu");
    const primaryNavHeight = primaryElement ? primaryElement.clientHeight : '';
    const stickySpaces = 20

    if (topMpuContainer) {
        if (isDesktop()) {
            monitorForChanges('.desktop-top-mpu', () => {
                const desktopTopMpu1Height = document.querySelector("#ad-desktopTopMpu1").offsetHeight
                if (desktopTopMpu1Height >= 600) {
                    topMpuContainer.classList.add('lg-desktopTopMpu1')
                } else {
                    if (topMpuContainer.classList.contains('lg-desktopTopMpu1')) {
                        topMpuContainer.classList.remove('lg-desktopTopMpu1')
                    }
                }
            })
        } else {
            monitorForChanges('.mobile-top-mpu', () => {
                const mobileTopMpu = document.querySelector(".mobile-top-mpu")
                const topMpuClassnameSizes = ['lg-mobileTopMpu1', 'fw-mobileTopMpu1']

                for (const topMpu of topMpuClassnameSizes) {
                    if (topMpuContainer.classList.contains(topMpu)) {
                        topMpuContainer.classList.remove(topMpu)
                    }
                }

                if (mobileTopMpu.offsetHeight >= 600 && mobileTopMpu.offsetWidth <= 300) {
                    topMpuContainer.classList.add('lg-mobileTopMpu1')
                } else {
                    if (mobileTopMpu.offsetWidth > 300) {
                        topMpuContainer.classList.add('fw-mobileTopMpu1')
                    }
                }

                mobileTopMpu.style.margin = 'auto'
                mobileTopMpu.style.padding = 'auto'
                mobileTopMpu.style.width = 'fit-content'
            });
        }
    }

    for (const stickyMpu of stickyMpus) {
        if (stickyMpu) {
            stickyMpu.style.top = `${primaryNavHeight + stickySpaces}px`
        }
    }
}

const nbaTickerMenu = document.querySelector(".nbap-nav--global");
const headerElement = document.querySelector("header");
const headerHeight = headerElement ? headerElement.clientHeight : 0;

const stickyNavigation = () => {
    const primaryElement = document.querySelector(".tsn-primary-menu");
    const primaryNavHeight = primaryElement ? primaryElement.clientHeight : 0;
    const tsnLogo = document.querySelector(".region-header")
    const navigation = document.querySelector('.tsn-primary-menu')
    const stickyNavigationthreshold = nbaTickerMenu ? nbaTickerMenu.clientHeight + headerHeight : headerHeight
    let documentScrolledValue = document.documentElement.scrollTop

    if (documentScrolledValue > stickyNavigationthreshold) {
        navigation.classList.add("tsn-primary-menu__fixed")
        tsnLogo.style.marginBottom = `${primaryNavHeight}px`
    } else {
        navigation.classList.remove("tsn-primary-menu__fixed")
        tsnLogo.style.marginBottom = '0px'
    }
}

export const stickyNavigationScrollHandler = () => {
    if (isDesktop()) {
        window.addEventListener('scroll', stickyNavigation);
    } else {
        window.removeEventListener('scroll', stickyNavigation);
    }
}

// Handles the execution of the hover behavior of the primary menu
export const menuHoverHandler = () => {
    const menuContainer = document.querySelector('.tsn-primary-menu__container')
    const noActiveTrail = !document.querySelector('.tsn-primary-menu__container .menu-item--active-trail')
    if (noActiveTrail) {
        menuContainer.classList.add('no-active-trail')
    }

    const menuItems = document.querySelectorAll('.primary-menu-wrapper > .menu > .menu-item:not(.menu-item--active-trail) > a')
    const additionalMenuItem = document.querySelectorAll('.additional-header--menu > .menu-item:not(.menu-item--active-trail) a')
    menuClassnameHandler([...menuItems, ...additionalMenuItem], 'not-hovered-menu')
}

// Handles the hover behavior of primary menu
const menuClassnameHandler = (items, classname) => {
    const noActiveTrail = !document.querySelector('.tsn-primary-menu__container .menu-item--active-trail')
    items.forEach(item => {
        item.addEventListener('mouseenter', () => {
            items.forEach(otherItem => {
                if (otherItem !== item) {
                    otherItem.classList.add(classname)

                    if (noActiveTrail && otherItem.parentElement.classList.contains('has-items')) {
                        otherItem.parentElement.querySelector('.chevron').classList.add(classname)
                    }
                }
            });
        });

        item.addEventListener('mouseleave', () => {
            items.forEach(otherItem => {
                otherItem.classList.remove(classname)

                if (noActiveTrail && otherItem.parentElement.classList.contains('has-items')) {
                    otherItem.parentElement.querySelector('.chevron').classList.remove(classname)
                }
            });
        });
    });
}

// Handles toggle of more items button (...)
export const moreItemsToggleHandler = () => {
    const addToggleListener = (selector) => {
        const moreItemsButton = document.querySelector(selector);
        if (moreItemsButton) {
            moreItemsButton.removeEventListener('click', toggleActiveClass);
            moreItemsButton.addEventListener('click', toggleActiveClass);
        }
    };

    if (isDesktop()) {
        addToggleListener('.tsn-primary-menu__top .desktop-primary-menu > .menu > .more.has-items');
        addToggleListener('.tsn-primary-menu__bottom .desktop-secondary-menu .menu > .more-second.has-items');
    }
};

function toggleActiveClass() {
    this.classList.toggle('is-active');
}

// Hightlight active sub menu under .more container
export const highlightSubNavHandler = () => {
    if (isDesktop()) {
        const menuBottomItems = document.querySelectorAll('.tsn-primary-menu__bottom .menu > .menu-item')

        primaryMenuBottomHandler(menuBottomItems)

        const menuTopItems = document.querySelectorAll(
            '.tsn-primary-menu__top > .tsn-primary-menu__container .primary-menu-wrapper .menu > .menu-item--active-trail .menu > .menu-item'
        )

        if (menuTopItems.length !== 0) {
            primaryMenuBottomHandler(menuTopItems)
        }
    } else {
        const menuBottomItems = document.querySelectorAll('.tsn-primary-menu__bottom .menu > .menu-item')
        if (menuBottomItems.length !== 0) {
            primaryMenuBottomHandler(menuBottomItems)
        }
    }
}

// Highlight sub menu on bottom container
const primaryMenuBottomHandler = (subMenu) => {
    const landingDraftPage = document.querySelector('.drafts-landing-page')
    const mockDraftPage = document.querySelector('.mock-draft-page')
    const roundByRoundPage = document.querySelector('.drafts-round-by-round-page')
    const teamByTeamPage = document.querySelector('.drafts-team-by-team-page')
    const articlePage = document.querySelector('.article-page')
    const urlHref = window.location.href

    const compareAnchorHref = (url, anchorHref) => {
        if (anchorHref !== '') {
            let parsedUrl = new URL(url);
            let [, firstUrlPathname, , ...remainingUrlPathnames] = parsedUrl.pathname.split('/');
            let urlWithoutSecondPathname = `/${[firstUrlPathname, ...remainingUrlPathnames].join('/')}`;

            let parsedAnchorHref = new URL(anchorHref);
            let [, firstAnchorPathname, , ...remainingAnchorPathnames] = parsedAnchorHref.pathname.split('/');
            let anchorHrefWithoutSecondPathname = `/${[firstAnchorPathname, ...remainingAnchorPathnames].join('/')}`;
            return urlWithoutSecondPathname === anchorHrefWithoutSecondPathname;
        }
    }

    if (articlePage) {
        let count = 0

        for (const menuTopItem of subMenu) {
            const anchorTag = menuTopItem.querySelector('a')

            if (isTemplateEnv() && anchorTag.href === urlHref) {
                count++
            } else {
                const isHrefEqual = compareAnchorHref(urlHref, anchorTag.href);
                if (isHrefEqual) {
                    count++
                }
            }
        }

        if (count > 0) {
            for (const menuTopItem of subMenu) {
                const anchorTag = menuTopItem.querySelector('a')
                if (isTemplateEnv()) {
                    const keywords = ['News', 'Noticias', 'ニュース', 'ข่าว'];

                    if (keywords.some(keyword => menuTopItem.textContent.includes(keyword)) && anchorTag.href.includes('news')) {
                        menuTopItem.classList.add('menu-item--active-trail');
                        menuTopItem.parentElement.classList.add('have-active-menu')
                    }
                } else {
                    const isHrefEqual = compareAnchorHref(urlHref, anchorTag.href);
                    if (isHrefEqual) {
                        menuTopItem.classList.add('menu-item--active-trail')
                        menuTopItem.parentElement.classList.add('have-active-menu')
                    }
                }
            }
        }

        if (count === 0) {
            for (const menuTopItem of subMenu) {
                const anchorTag = menuTopItem.querySelector('a')
                const keywords = ['News', 'Noticias', 'ニュース', 'ข่าว'];

                if (keywords.some(keyword => menuTopItem.textContent.includes(keyword)) && anchorTag.href.includes('news')) {
                    menuTopItem.classList.add('menu-item--active-trail');
                    menuTopItem.parentElement.classList.add('have-active-menu')
                }
            }
        }
    } else if (landingDraftPage || mockDraftPage || roundByRoundPage || teamByTeamPage) {
        for (const menuTopItem of subMenu) {
            const anchorTag = menuTopItem.querySelector('a')
            if (menuTopItem.textContent.includes('Draft') && anchorTag.href.includes('draft')) {
                menuTopItem.classList.add('menu-item--active-trail')
                menuTopItem.parentElement.classList.add('have-active-menu')
            }
        }
    } else {
        for (const menuTopItem of subMenu) {
            const anchorTag = menuTopItem.querySelector('a')

            if (urlHref === anchorTag.href) {
                menuTopItem.classList.add('menu-item--active-trail')
                menuTopItem.parentElement.classList.add('have-active-menu')
            }
        }
    }
}

// Hide primary menu bottom
export const hidePrimaryMenuBottomHandler = () => {
    const primaryMenuBottom = document.querySelector('.tsn-primary-menu__bottom')
    const emptySubMenu = !document.querySelector('.menu-item--active-trail > .menu > .menu-item')
    const nbaMenu = document.querySelector('.nba-menu-wrapper > .nba-menu-inner > .menu')


    if (nbaMenu && primaryMenuBottom) {
        primaryMenuBottom.classList.add('secondary-nba')
    }

    if (emptySubMenu && !nbaMenu && primaryMenuBottom) {
        primaryMenuBottom.classList.add('secondary-empty')
    }
}

const childrenArrowContainer = () => {
    const listContainer = document.createElement('i')
    listContainer.classList.add('has-children__arrow')

    return listContainer
}

const childrenSecondArrowContainer = () => {
    const listContainer = document.createElement('i')
    listContainer.classList.add('has-children-second__arrow')

    return listContainer
}

export const additionalNavHandler = () => {
    let tsnAccountWrap = document.querySelector('.tsn-account-menu-wrapper');
    let additionalMenu = document.querySelector('.additional-menu-wrapper');

    if (tsnAccountWrap && additionalMenu) {
        additionalMenu.style.marginRight = tsnAccountWrap.clientWidth + 'px';
    }
}

// Hide dropdown menus when clicked outside of them: menu and edition dropdown
export const menuDropdownToggle = () => {
    if (isDesktop()) {
        document.addEventListener('click', (event) => {
            const primaryMenuMoreItems = document.querySelector('.desktop-primary-menu .more');
            const secondaryMenuMoreItems = document.querySelector('.desktop-secondary-menu .more-second');
            const editionDropdown = document.querySelector('#block-tsndomainnavigation .menu');

            if (!event.target.closest('.desktop-primary-menu .more.is-active') && primaryMenuMoreItems) {
                primaryMenuMoreItems.classList.remove('is-active');
            }

            if (!event.target.closest('.desktop-secondary-menu .more-second.is-active') && secondaryMenuMoreItems) {
                secondaryMenuMoreItems.classList.remove('is-active');
            }

            if (!event.target.closest('#block-tsndomainnavigation .menu.open') && editionDropdown) {
                editionDropdown.classList.remove('open');
            }
        });
    }
}
