import { isMobile } from '../../common/_constants'


export const scrollToTargetElement = () => {
    const scrollLinks = document.querySelectorAll('.right-sidepanel__page-links ul li a');

    scrollLinks.forEach((link, index) => {
        if (index === 0) return;

        link.addEventListener('click', function(event) {
            event.preventDefault();

            const ahref = this.getAttribute('href');
            const targetElement = document.getElementById(ahref.substring(1));

            if (targetElement) {
                const offsetTop = targetElement.offsetTop;

                if (window.innerWidth <= 768) {
                    document.body.scrollTo({ top: offsetTop, behavior: 'smooth' });
                } else {
                    window.scrollTo({ top: offsetTop, behavior: 'smooth' });
                }
            }
        });
    });
}