export const rebuildTables = () => {
    const tables = document.querySelectorAll('table')

    if (tables) {
        tables.forEach(table => {
            // Create a new container div element
            const container = document.createElement('div')
            const parent = table.parentNode

            container.className = 'table-container'

            parent.insertBefore(container, table)

            container.appendChild(table)
        })
    }
}
