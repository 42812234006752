const handleMediaQueryChange = (mobileCallback, desktopCallback) => {
    const mediaQueryMobile = '(max-width: 768px)';
    const mediaQueryListMobile = window.matchMedia(mediaQueryMobile);

    const mediaQueryDesktop = '(min-width: 769px)';
    const mediaQueryListDesktop = window.matchMedia(mediaQueryDesktop);

    const handleMobileChange = (event) => {
        if (event.matches && mobileCallback) {
            mobileCallback();
        }
    };

    const handleDesktopChange = (event) => {
        if (event.matches && desktopCallback) {
            desktopCallback();
        }
    };

    mediaQueryListMobile.addEventListener('change', handleMobileChange);
    mediaQueryListDesktop.addEventListener('change', handleDesktopChange);

    // Initial check for the current state
    handleMobileChange(mediaQueryListMobile);
    handleDesktopChange(mediaQueryListDesktop);
};

export default handleMediaQueryChange;
