export function ribbonPlacementHandler() {
    const nbaRibbon = document.querySelector(".nba-scoreboard-wrapper");
    const nbaRibbonContainer = document.querySelector(
        ".nba-scoreboard-container",
    );

    const e2Ribbon = document.querySelector(
        ".oddsServeCreative.schedule_creative",
    );
    const e2RibbonContainer = document.querySelector(
        ".e2-schedule-ribbon-container",
    );

    const ruwtRibbon = document.querySelector(".tsn-score-ribbon");
    const ruwtRibbonContainer = document.querySelector(
        ".zephr-feature_scoreboard-ribbon-widget",
    );

    const ribbonsAndContainers = [
        [nbaRibbon, nbaRibbonContainer],
        [e2Ribbon, e2RibbonContainer],
        [ruwtRibbon, ruwtRibbonContainer],
    ];
    const mainContainer = document.querySelector(".layout-container > main");

    const moveRibbonsToMain = () => {
        for (const ribbonAndContainer of ribbonsAndContainers) {
            const ribbon = ribbonAndContainer[0];
            if (ribbon && mainContainer) {
                mainContainer.insertBefore(ribbon, mainContainer.firstChild);
            }
        }
    };

    const revertRibbonsToOriginalLocation = () => {
        for (const ribbonAndContainer of ribbonsAndContainers) {
            const ribbon = ribbonAndContainer[0];
            const ribbonContainer = ribbonAndContainer[1];
            if (ribbon && ribbonContainer) {
                ribbonContainer.insertBefore(
                    ribbon,
                    ribbonContainer.firstChild,
                );
            }
        }
    };

    if (window.matchMedia("(max-width: 768px)").matches) {
        moveRibbonsToMain();
    } else {
        revertRibbonsToOriginalLocation();
    }
}
