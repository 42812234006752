export const monitorForChanges = (selector, callback) => {
    const targetElement = document.querySelector(selector)

    const config = { attributes: true, childList: true, subtree: true }

    const observer = new MutationObserver(callback)

    observer.observe(targetElement, config)
}

const waitForElm = (getElementFunction) => {
    return new Promise(resolve => {
        const element = getElementFunction()
        if (element) {
            return resolve(element)
        }

        const observer = new MutationObserver(mutations => {
            const element = getElementFunction()
            if (element) {
                resolve(element)
                observer.disconnect()
            }
        });

        observer.observe(document.body, {
            childList: true,
            subtree: true
        });
    });
}

export const waitForElmBySelectorIndex = (selector, index) => {
    return waitForElm(() => document.querySelectorAll(selector)[index])
}

export const lazyLoadHandler = (selector, articleImage, closestItem, removeClassname) => {
    const targetElements = document.querySelectorAll(selector)

    if (targetElements) {
        let indexCount = 0
        const imageObserver = new IntersectionObserver(
            (entries, imgObserver) => {
                for (const entry of entries) {
                    if (entry.isIntersecting) {
                        const lazyImage = entry.target
                        const loadImage = document.querySelectorAll(articleImage)[indexCount]

                        loadImage.src = loadImage.dataset.src
                        loadImage.closest(closestItem).classList.remove(removeClassname)
                        lazyImage.remove()

                        indexCount += 1
                        imgObserver.unobserve(lazyImage)
                    }
                }
            },
            { threshold: 0.5 }
        )

        for (const targetElement of targetElements) {
            imageObserver.observe(targetElement)
        }
    }
}

export const loadMoreItemsHandler = (itemsContainer, loadCount, addLoadCount, loadMoreButton) => {
    if (loadCount >= itemsContainer.length) loadMoreButton.remove()
    loadMoreButton.addEventListener('click', () => {
        if (loadCount <= itemsContainer.length) {
            const setLoadCount = loadCount + addLoadCount
            const setItemsContainer = itemsContainer.slice(loadCount, setLoadCount)

            for (const setItemContainer of setItemsContainer) {
                setItemContainer.style.display = 'block'
            }

            loadCount += addLoadCount

            if (loadCount >= itemsContainer.length) {
                loadMoreButton.remove()
            }
        }

        if (loadMoreButton.innerHTML !== 'Load More') loadMoreButton.innerHTML = 'Load More'
    })
}

export const removeServiceWorker = () => {
    if ('serviceWorker' in window.navigator) {
        window.addEventListener('load', () => {
            window.navigator.serviceWorker.getRegistrations().then((registrations) => {
                if (registrations.length > 0) {
                    const unregisterServiceWorkers = ['workbox-54e9ef81.js', 'sw.js'];
                    for (const registration of registrations) {
                        if (!registration.active) {
                            continue;
                        }
                        unregisterServiceWorkers.forEach((worker) => {
                            if (registration.active.scriptURL.includes(worker)) {
                                registration.unregister();
                            }
                        });
                    }
                }
            })
        })
    }
}

export const lazyLoadTweetsHandler = () => {
    const twitterTweets = document.querySelectorAll('.twitter-tweet')
    const txtArray = []

    if (twitterTweets.length > 0) {
        twitterTweets.forEach((tweet, index) => {
            const twitterLink = tweet.querySelector('a').getAttribute('href')
            const div = document.createElement('div')
            div.className = 'lozad'
            div.setAttribute('data-twitter-src', twitterLink)
            div.setAttribute('data-order', index)
            tweet.parentNode.replaceChild(div, tweet)
            txtArray.push(tweet.innerHTML)
        })


        const observer = lozad('.lozad', {
            load(element) {
                const bq = document.createElement('blockquote')
                bq.className = 'twitter-tweet'

                const div = document.createElement('div')
                div.innerHTML = txtArray[element.dataset.order]
                bq.append(div)

                const script = document.createElement('script')
                script.src = 'https://platform.twitter.com/widgets.js'
                script.charset = 'utf8'
                script.async = true

                element.insertAdjacentElement('beforeEnd', bq)
                element.insertAdjacentElement('beforeEnd', script)
            },
        })
        observer.observe()
    }
}

export function debounce(callback) {
    let timeoutId;

    return function () {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(callback, 300);
    };
}
