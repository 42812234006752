export const authorsImageAndTextHandler = () => {
    const authorsName = document.querySelectorAll('.author-top-section .author-group .author-name')
    const otherAuthorsText = document.querySelector('.author-top-section .author-group .author-others')
    const authorsImageContainer = document.querySelector('.author-top-section__image')
    const authorsImages = authorsImageContainer.querySelectorAll('img')

    if (authorsName.length > 1) {
        // Remove authors image
        for (const authorsImage of authorsImages) {
            authorsImage.remove()
        }

        // Diplay image for multiple authors
        authorsImageContainer.classList.add('multiple-authors')

        if (authorsName.length > 4) {
            // Remove other authors name
            let count = 0
            for (const authorName of authorsName) {
                if (count > 3 && count <= authorsName.length) {
                    authorName.remove()
                }

                count++
            }

            // Display 'and others' text
            otherAuthorsText.classList.add('show')
        }
    } else {
        if (authorsImages.length > 0 && authorsImages[0].classList.contains('loader')) {
            // Display authors image
            authorsImages[0].classList.remove('loader')
        }
    }
}
