import { isDesktop, showElem, hideElem } from "../../common/_constants";

export const keyboardNavigation = () => {

    if(isDesktop){
        document.addEventListener('keydown', e => {
            const skiptocontentElem = document.querySelector('#skiptocontent');
            
            if(e.key === " ") {
                /* Disable spacebar default event while "skip to main content" button is active */
                if(skiptocontentElem.classList.contains('active')) {
                    e.preventDefault();
                }
            }
        });

        document.addEventListener('keyup', e => {
            const skiptocontentElem = document.querySelector('#skiptocontent');

            const activeElem = e.target;
            const key = e.key;

            switch (key) {
                case "Tab": {
                    if(activeElem.href?.match(/#main-content/) && !skiptocontentElem.classList.contains('active')){
                        showElem(skiptocontentElem);
                    } else if(!activeElem.href?.match(/#main-content/) && skiptocontentElem.classList.contains('active')) {
                        hideElem(skiptocontentElem);
                    }
                    break;
                }
                case "Escape": {
                    if(skiptocontentElem.classList.contains('active')) {
                        hideElem(skiptocontentElem);
                    }
                    break;
                }
                case "Enter": {
                    if(skiptocontentElem.classList.contains('active')) {
                        hideElem(skiptocontentElem);
                    }
                    break;
                }
                case " ": {
                    if(skiptocontentElem.classList.contains('active')) {
                        activeElem.click()
                    }
                    break;
                }
            }
        });
    }
}