import { isMobile, isDesktop } from '../../common/_constants'

export const nbaLogoHandler = () => {
    const bottomPrimaryMenu = document.querySelector('.tsn-primary-menu__bottom')
    const isNbaSibPage = document.querySelector('.tsn-primary-menu.with-global')
    const isArticlePage = document.querySelector('.article-page')
    const nbaSibHeader = document.querySelectorAll(".tsn-primary-menu__bottom .nba-sib-header")
    const mobileNbaBanner = document.querySelector(".layout-container > .watch-league-pass")

    if (isNbaSibPage && isMobile()) {
        // Handles mobile NBA title and logos
        let nbaSibHeaderElement = document.createElement("div")
        nbaSibHeaderElement.classList.add("nba-sib-header")

        const nbaSibHeader = document.querySelector('.nba-sib-header')
        const nbaExperience = document.querySelector('.tsn-advertising-section > .nba-experience-container')
        const nbaExperienceMobile = document.querySelector('.nba-experience-container.nba-experience-mobile')
        const nbaExperiencePlacement = [
            document.querySelector('.zephr-feature_more-news-content-body'),
            document.querySelector('.layout-content .news-section'),
            document.querySelector('.section-page .layout-content .top-news > .top-news__list'),
        ]
        const watchLeaguePass = document.querySelector(".desktop-secondary-menu .nba-menu-wrapper .watch-league-pass")
        const watchLeaguePassMobile = document.querySelector('.layout-container main > .watch-league-pass')
        const nbaRibbon = document.querySelector(".nba-scoreboard-wrapper")
        const prologueContent = document.querySelector("[data-page-type]")
        const layoutContent = document.querySelector(".layout-content")
        const main = document.querySelector("main")
        let alreadyAppended = false

        if (!alreadyAppended && !nbaSibHeader) {
            bottomPrimaryMenu.appendChild(nbaSibHeaderElement)

            if (!watchLeaguePassMobile && watchLeaguePass && isArticlePage) {
                if (nbaRibbon) {
                    nbaRibbon.insertAdjacentElement('afterend', watchLeaguePass.cloneNode(true));
                } else {
                    main.insertAdjacentElement('beforebegin', watchLeaguePass.cloneNode(true));
                }
            }

            if (!watchLeaguePassMobile && watchLeaguePass && !isArticlePage) {
                if (prologueContent) {
                    prologueContent.insertAdjacentElement('beforebegin', watchLeaguePass.cloneNode(true));
                }
                if (layoutContent) {
                    layoutContent.insertAdjacentElement('beforebegin', watchLeaguePass.cloneNode(true));
                }
            }

            for (const element of nbaExperiencePlacement) {
                if (!nbaExperienceMobile && element) {
                    element.insertAdjacentElement('afterend', nbaExperience.cloneNode(true))
                    element.parentNode.querySelector('.nba-experience-container').classList.add('nba-experience-mobile')
                }
            }
            alreadyAppended = true
        } else if (alreadyAppended) {
            if (nbaSibHeader) {
                nbaSibHeaderElement.remove()
            }

            if (mobileNbaBanner) {
                mobileNbaBanner.remove()
            }
            alreadyAppended = false
        }
    }
    else if (isNbaSibPage && isDesktop()) {
        if (nbaSibHeader) {
            nbaSibHeader.forEach(element => {
                element.remove()
            })
        }

        if (mobileNbaBanner) {
            mobileNbaBanner.remove()
        }
    }
}
