import { monitorForChanges } from "../helpers/_observer-handler";

export const updateGridColumn = () => {
    const oddsData = '.metabet-odds-board .metabet-odds-board-lines'
    monitorForChanges(oddsData, () => {
        const oddsBoard = document.querySelector(".metabet-odds-board .metabet-odds-board-lines")
        const oddsProviders = document.querySelector(".metabet-odds-board .metabet-odds-board-header-providers")
        const oddsTeams = document.querySelector(".metabet-odds-board-scoreboards")

        const newRowHeight = oddsBoard.classList.contains("metabet-odds-board-market-overUnder") ? "160px" : "145px"
        const columLength = oddsProviders.querySelectorAll("div").length
        const getBoardRows = oddsBoard.style.gridTemplateRows
        const getTeamsRows = oddsTeams.style.gridTemplateRows
        const newBoardRows = getBoardRows.replace("120px", newRowHeight)
        const newTeamsRows = getTeamsRows.replace("120px", newRowHeight)

        document.documentElement.style.setProperty('--metabet-odds-board-header-height', '120px');
        oddsTeams.style.gridTemplateRows = newTeamsRows
        oddsBoard.style.gridTemplateRows = newBoardRows
        oddsBoard.style.gridTemplateColumns = `repeat(${columLength}, minmax(182.5px, 1fr))`;
        oddsProviders.style.gridTemplateColumns = `repeat(${columLength}, minmax(182.5px, 1fr))`;
    })
}
