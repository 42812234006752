import Glide from '@glidejs/glide'
import { monitorForChanges } from '../helpers/_observer-handler'
import { isMobile } from '../../common/_constants'

export const glideConfig = (config) => {
    const glide = new Glide('.glide', config)

    glide.mount()
}

export const glideSliderHandler = () => {
    const slidesContainer = document.querySelector('.glide__slides')
    const activeSlideImg = document.querySelector('.glide__slide.glide__slide--active img')
    const slideControls = document.querySelectorAll('.glide button.slider__arrow')

    monitorForChanges('.glide__slides', () => {
        const slideContent = document.querySelector('.glide__slide.glide__slide--active .slide-gallery-content')
        if (slideContent) {
            for (const slideControl of slideControls) {
                slideControl.style.top = `calc(50% - ${slideContent.clientHeight / 2}px)`
            }
        }
    })

    monitorForChanges('.glide__slide.glide__slide--active', () => {
        const slideContent = document.querySelector('.glide__slide.glide__slide--active .slide-gallery-content')
        if (slideContent) {
            slidesContainer.style.height = `${activeSlideImg.clientHeight + slideContent.clientHeight}px`
        }
    })
}

export const glideCarouselConfig = (config, indexThreshold) => {
    const glide = new Glide('.glide', config);
    const slides = document.querySelectorAll('.glide ul li')

    const mutator = function (Glide, Components, Events) {
        return {
            modify(translate) {
                if (indexThreshold) {

                    if (Glide.index === 0) {
                        return translate;
                    } else if (Glide.index === Components.Sizes.length - 1) {
                        return translate - (Components.Sizes.width - Components.Sizes.slideWidth);
                    } else {
                        return translate - (Components.Sizes.width - Components.Sizes.slideWidth) / indexThreshold;
                    }
                } else {
                    return translate
                }
            },
        };
    };

    glide.mutate([mutator]).mount();

    if (slides.length === 1) {
        for (const slide of slides) {
            slide.style.width = '100%'
            slide.parentElement.style.width = '100%'
        }
    }
}

export const partnerContentSlider = () => {
    const slideRelatedNewsEnabled = document.querySelector("[data-enable-slider='true']")
    if (slideRelatedNewsEnabled) {
        const slides = document.querySelectorAll("[data-enable-slider='true'] .glide__slide")
        const slideCount = slides.length
        const slideControler = document.querySelector("[data-glide-el='controls']")

        if (isMobile()) {
            glideCarouselConfig({
                type: "slider",
                perView: slideCount >= 2 ? 1.5 : 1,
                focusAt: 0,
                bound: slideCount >= 2,
                gap: 10,
                rewind: false,
            }, 2)

            if (slideCount < 2) {
                slideControler.classList.add('hidden');
            } else {
                slideControler.classList.remove('hidden');
            }
        } else {
            glideCarouselConfig({
                type: 'slider',
                focusAt: 0,
                startAt: 0,
                perView: 4,
                bound: slideCount >= 4,
                swipeThreshold: slideCount >= 4,
                dragThreshold: slideCount >= 4,
                keyboard: slideCount >= 4,
                rewind: false,
                gap: 12,
            })

            if (slideCount < 4) {
                slideControler.classList.add('hidden');
            } else {
                slideControler.classList.remove('hidden');
            }
        }
    }
}

export const relatedNewsSlider = () => {
    if (isMobile()) {
        glideCarouselConfig({
            type: "slider",
            perView: 1.25,
            focusAt: 0,
            gap: 20,
            bound: true,
            gap: 10,
            rewind: false,
        }, 2)
    } else {
        glideConfig({
            type: 'slider',
            startAt: 0,
            perView: 3,
            swipeThreshold: false,
            dragThreshold: false,
            keyboard: false,
            gap: 35,
        })
    }
}

export const gallerySlider = () => {
    glideConfig({
        type: 'slider',
        touchAngle: 45,
        focusAt: 0,
        startAt: 0,
        keyboard: true,
        gap: 0,
        rewind: false,
    })
    glideSliderHandler()
}
