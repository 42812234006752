import { isMobile } from '../../common/_constants'

export const addTextStyle = () => {
    // Team names styles
    const teamNames = document.querySelectorAll('.tracker-block__content .team-name')
    if (teamNames) {
        for (const teamName of teamNames) {
            const splitNames = teamName.innerHTML.split(' ')
            const lastWord = splitNames.pop()
            const firstWord = splitNames.join(' ')
            teamName.innerHTML = `${firstWord} <br><b>${lastWord}</b>`
        }
    }

    // Player name style
    const playerNames = document.querySelectorAll('.player-name')
    const lastPickPlayerName = document.querySelector('.last-pick .player-name')
    const completedDraft = document.querySelector('.tracker-block--completed')
    if (completedDraft) {
        for (const playerName of playerNames) {
            const splitNames = playerName.innerHTML.split(' ')
            const lastWord = splitNames.pop()
            const firstWord = splitNames.join(' ')
            playerName.innerHTML = `${firstWord}<br>${lastWord}`
        }
    } else {
        if (lastPickPlayerName) {
            const splitNames = lastPickPlayerName.innerHTML.split(' ')
            const lastWord = splitNames.pop()
            const firstWord = splitNames.join(' ')
            lastPickPlayerName.innerHTML = `${firstWord}<br>${lastWord}`
        }
    }
}

export const setScrollBehaviour = () => {
    // Set horizontal scroll to center
    const trackerContainerWidth = document.querySelector('.content-wrapper .content-container')
    if (trackerContainerWidth) {
        const trackerWrapper = document.querySelector('.content-wrapper')
        let initalPositionX = trackerContainerWidth.offsetWidth / 2 - window.innerWidth / 2
        const nextPickContainer = document.querySelector('.next-pick')
        const lastPickContainer = document.querySelector('.last-pick')

        // Initial scroll position
        trackerWrapper.scrollLeft = initalPositionX

        // Scroll behaviour
        trackerWrapper.addEventListener('scroll', () => {
            if (isMobile()) {
                initalPositionX = trackerContainerWidth.offsetWidth / 2 - window.innerWidth / 2
                if (trackerWrapper.scrollLeft > initalPositionX) {
                    nextPickContainer.style.transform = 'translate(0px)'
                    lastPickContainer.style.transform = 'translate(55px)'
                } else if (trackerWrapper.scrollLeft < initalPositionX) {
                    lastPickContainer.style.transform = 'translate(0px)'
                    nextPickContainer.style.transform = 'translate(-55px)'
                } else {
                    lastPickContainer.style.transform = 'translate(55px)'
                    nextPickContainer.style.transform = 'translate(-55px)'
                }
            } else {
                lastPickContainer.style.transform = 'unset'
                nextPickContainer.style.transform = 'unset'
            }
        })
    }
}

export const initialCheckedHandler = () => {
    // Set checked, first iteration of input radio.
    const radioBtn = document.querySelectorAll('.tabs input')
    radioBtn[0].checked = true
}

// Refresh the page to load the updated tracker if there is one.
export const refreshDraftPage = () => {
    setInterval(function() {
        window.location.reload();
    }, 30000);
}
